@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./fonts.css");

@layer base {
  .widget-container {
    all: unset;
  }
}

.markdown-wrapper h1,
.markdown-wrapper h2,
.markdown-wrapper h3 {
  @apply !text-lg !font-bold !font-sans !text-left;
}

.markdown-wrapper p {
  @apply !text-sm !leading-relaxed !font-sans !m-0 !text-left;
}

.markdown-wrapper a {
  @apply !text-blue-600 !underline !font-sans !text-left;
}

.markdown-wrapper strong {
  @apply !font-bold !font-sans;
}

.markdown-wrapper em {
  @apply !italic !font-sans;
}

.markdown-wrapper ul,
.markdown-wrapper ol {
  @apply !list-disc !ml-6 !font-sans !text-left;
}

.markdown-wrapper li {
  @apply !text-sm !font-sans !text-left !list-none;
}

.markdown-wrapper blockquote {
  @apply !border-l-4 !pl-4 !italic !font-sans !text-left;
}

.markdown-wrapper pre {
  @apply !bg-gray-100 !p-4 !rounded !font-sans !text-left;
}

.markdown-wrapper code {
  @apply !bg-gray-200 !p-1 !rounded !text-xs !font-sans !text-left;
}

.markdown-wrapper img {
  @apply !max-w-full !h-auto !font-sans !text-left;
}

.markdown-wrapper table {
  @apply !w-full !table-auto !border-collapse !font-sans !text-left;
}

.markdown-wrapper th,
.markdown-wrapper td {
  @apply !border !p-2 !font-sans !text-left;
}

.markdown-wrapper thead {
  @apply !bg-gray-100 !font-sans !text-left;
}

.markdown-wrapper hr {
  @apply !border-t !my-4 !font-sans !text-left;
}

.full-screen-container {
  height: -webkit-fill-available;
  height: 100dvh;
}
