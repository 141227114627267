@font-face {
  font-family: "New Atten Round";
  src: url("./fonts/NewAttenRound-Regular.woff2") format("woff2"),
    url("./fonts/NewAttenRound-Regular.woff") format("woff"),
    url("./fonts/NewAttenRound-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "New Atten Round";
  src: url("./fonts/NewAttenRound-Bold.woff2") format("woff2"),
    url("./fonts/NewAttenRound-Bold.woff") format("woff"),
    url("./fonts/NewAttenRound-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
